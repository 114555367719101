<template>
    <div>
        <img src="@/assets/solutionpdf/fwsy_1.jpg" alt="">
        <img src="@/assets/solutionpdf/fwsy_2.jpg" alt="">
        <img src="@/assets/solutionpdf/fwsy_3.jpg" alt="">
        <img src="@/assets/solutionpdf/fwsy_4.jpg" alt="">
        <img src="@/assets/solutionpdf/fwsy_5.jpg" alt="">
    </div>
</template>

<script>
export default {
    name: "fwsy-solution"
}
</script>

<style scoped>
img{
    width: 100%;
}
</style>